(function() {
  $(function() {
    $('[data-toggle="sidebar"]').on('click', function(e) {
      e.preventDefault();
      return $($(this).data('target')).trigger('slideIn');
    });
    $('#sidebar').on('slideIn', function() {
      $(this).addClass('in');
      return $(this).after($('<div id="sidebar-backdrop">'));
    }).on('slideOut', function() {
      $(this).removeClass('in');
      return $('#sidebar-backdrop').fadeOut(0.2, function() {
        return $(this).remove();
      });
    });
    $(document).on('click', '#sidebar-backdrop', function(e) {
      e.stopPropagation();
      e.preventDefault();
      return $('#sidebar').trigger('slideOut');
    });
    return $('#sidebar-nav .submenu').on('show.bs.collapse', function() {
      $(this).closest('li').addClass('open');
      return $('.submenu.in, .submenu.collapsing', '#sidebar-nav').collapse('hide');
    }).on('hide.bs.collapse', function() {
      return $(this).closest('li').removeClass('open');
    });
  });

}).call(this);