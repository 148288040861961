$(function() {
  const STUDY_ROLE_CHECKBOXES = "#study-roles input[type='checkbox']"
  const SITE_ROLE_CHECKBOXES = "#site-roles input[type='checkbox']"

  // If everything is unchecked, then show a button with a confirmation dialogue
  var setConfirmationIfAllRolesRemoved = function () {
    var anyStudyRoles = $(STUDY_ROLE_CHECKBOXES + ":checked").length > 0
    var anySiteRoles = $(SITE_ROLE_CHECKBOXES + ":checked").length > 0

    if (anyStudyRoles || anySiteRoles) {
      $('#save-button').data("confirm", "")
    } else {
      $('#save-button').data("confirm", "Removing all roles will remove this user from the study. Are you sure you wish to remove them?")
    }
  }

  $(STUDY_ROLE_CHECKBOXES + ", " + SITE_ROLE_CHECKBOXES).change(function (e) {
    setConfirmationIfAllRolesRemoved();
  });

  setConfirmationIfAllRolesRemoved();

  // Code for user role screen deselect button
  $('#deselect-all').click(() => {
    const STUDY_ROLE_CHECKBOXES = "#admin-study-roles input[type='checkbox']";
    let checkboxes = $(STUDY_ROLE_CHECKBOXES);

    checkboxes.prop('checked', false);
  });
});
