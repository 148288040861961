(function() {
  $(function() {
    $('[data-users-available]').hide();
    return $('#existing-organisation-select').on('ajax:success', function(e, results) {
      var users;
      $('#existing-user-select').empty();
      users = results.users;
      if (users.length > 0) {
        $('[data-users-available="show"]').show();
        $('[data-users-available="hide"]').hide();
        return $.each(results.users, function() {
          return $('#existing-user-select').append($('<option>').val(this.id).html(this.name));
        });
      } else {
        $('[data-users-available="show"]').hide();
        return $('[data-users-available="hide"]').show();
      }
    });
  });

}).call(this);