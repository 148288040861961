(function() {
  (function($) {
    return $.flashAlert = function(message, type, timeout) {
      var $alert, icon;
      if (timeout == null) {
        timeout = 10000;
      }
      icon = type === 'alert-success' ? '<i class="fa fa-check text-success"></i>' : '<i class="fa fa-warning text-danger"></i>';
      $alert = $("<div class=\"alert fade\">\n  <div class=\"container\">\n    " + icon + "\n    " + message + "\n    <a class=\"small\" data-dismiss=\"alert\" href=\"#\" title=\"Hide this message\">Dismiss.</a>\n  </div>\n</div>");
      if ($('.flash-messages .alert').length > 2) {
        clearTimeout($('.flash-messages .alert:last').remove().data('timeout'));
      }
      $('.flash-messages').prepend($alert.addClass('in'));
      $alert.data('timeout', setTimeout(function() {
        return $alert.alert('close');
      }, timeout));
      return true;
    };
  })(jQuery);

  $(function() {
    if ($('.flash-messages .alert').length > 0) {
      $('.flash-messages .alert').addClass('in');
      return setTimeout("$('.flash-messages .alert').alert('close');", 10000);
    }
  });

}).call(this);